<template>
	<div class="hello">
		<div class="login_header">
			<img class="login_header_bg" src="../assets/1.png" alt="">
			<div class="login_header_content">
				<div class="login_header_one" @click="openmap">
					<img src="../assets/2.png" alt="">
					<span>{{povicetext}} {{citytext}}</span>
				</div>
				<div class="login_header_two">
					<div class="login_header_two_list">
						<span>{{basedetail.tel}}</span>
					</div>
					<div class="login_header_two_list" @click="goselfcenter(4)">
						<span>帮助中心</span>
						<img src="../assets/3.png" alt="">
					</div>
					<div class="login_header_two_list" @click="goselfcenter(3)">
						<span>商家服务</span>
					</div>
					<div class="login_header_two_list" @click="goselfcenter(1)">
						<span>我的订单</span>
					</div>
					<div class="login_header_two_list" @click="goselfcenter(2)">
						<span>个人中心</span>
					</div>
					<div class="login_header_two_list" style="color: #FF5050;" @click="loginregir(2)" v-if="!userInfo">
						<span>注册</span>
					</div>
					<div class="login_header_two_list" style="color: #0066FF;border-left: none;" @click="loginregir(1)"
						v-if="!userInfo">
						<span>登录</span>
					</div>
					<div class="login_header_two_list" style="border-left: none;" @click="login_outshow = true"
						v-if="userInfo">
						<img class="user_img" :src="userInfo.avatar" alt="">
						<span class="login_header_two_list_loginout">{{userInfo.nickname}}</span>
						<span class="login_header_two_list_loginout" style="color: #0066FF;">【退出登录】</span>
					</div>
				</div>
				<div class="header_three">
					<div class="header_three_logo" @click="goindex">
						<img src="../assets/logo.png" alt="">
					</div>
					<div class="header_three_search">
						<div class="header_three_search_one">
							<div class="header_three_search_one_l">
								<input type="text" maxlength="10" placeholder="请输入商品名称、型号、规格、品牌" v-model="keywords"
									@keyup.enter="gosearch">
								<div class="header_three_search_one_l_btn" @click="gosearch">
									<img src="../assets/4.png" alt="">
									<span>搜索</span>
								</div>
							</div>
							<div class="header_three_search_one_r" @click="gocart">
								<div class="header_three_search_one_r_cart">
									<img src="../assets/5.png" alt="">
									<span v-if="carttotal>0">{{carttotal}}</span>
								</div>
								<span>购物车</span>
							</div>
						</div>
						<div class="header_three_search_bottom" @click="searchshow = true">
							<div class="header_three_search_bottom_list" v-for="item in searchhistory.slice(0,8)">
								<span>{{item}}</span>
							</div>
						</div>
						<div class="search_tanchuang" v-if="searchshow">
							<div class="search_tanchuang_list" v-for="(item,index) in searchhistory.slice(0,8)">
								<span @click.stop="searcgclick(item)">{{item}}</span>
								<img src="../assets/35.png" alt="" @click.stop="delateclick(index)">
							</div>
							<div class="search_tanchuang_bottom" @click="clearall">
								<img src="../assets/36.png" alt="">
								<span>全部删除</span>
							</div>
						</div>
					</div>
				</div>
				<!-- 城市选择弹窗 -->
				<div class="check_city" v-if="countryshow">
					<div class="check_city_header">
						<div class="check_city_header_list check_city_header_list_now" @click="openpovice">
							<span>{{povicetext}}</span>
						</div>
						<div class="check_city_header_list check_city_header_list_now" @click="opencity">
							<span>{{citytext}}</span>
						</div>
					</div>
					<!-- 省的展示模块 -->
					<div class="check_city_content" v-if="poviceshow">
						<div class="check_city_content_list" v-for="item in povice"
							:class="{'check_city_content_list_now':povicetext==item.name}" @click="poviceclick(item)">
							<span>{{item.name}}</span>
						</div>
					</div>
					<!-- 市展示模块 -->
					<div class="check_city_content" v-if="cityshow">
						<div class="check_city_content_list" v-for="item in citylist"
							:class="{'check_city_content_list_now':citytext==item.name}" @click="cityclick(item)">
							<span>{{item.name}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 二级头部 -->
		<div class="header_two">
			<div class="header_two_content">
				<div class="header_two_list_one" @click="fenleiclick">
					<img src="../assets/6.png" alt="">
					<span>全部商品</span>
					<div class="header_two_list_one_fenlei" v-if="fenleishow">
						<div class="header_two_list_one_fenlei_l">
							<div class="header_two_list_one_fenlei_l_yijilist"
								:class="{'header_two_list_one_fenlei_l_yijilist_now':item.aid==yijiaid}"
								v-for="(item,index) in categorylist" @click.stop="yijiclick(item.children,item.aid)">
								<span>{{item.name}}</span>
								<img src="../../src/assets/160.png" alt="">
							</div>
						</div>
						<div class="header_two_list_one_fenlei_l_erji">
							<div class="header_two_list_one_fenlei_l_erji_list" v-for="list in erjifenlei"
								@click.stop="erjiclick(list)">
								<div class="header_two_list_one_fenlei_l_erji_list_l">
									<span>{{list.name}}></span>
								</div>
								<div class="header_two_list_one_fenlei_l_erji_list_r">
									<div class="header_two_list_one_fenlei_sanjilist" v-for="sanji in list.ad_list"
										@click.stop="snajiclick(sanji.aid)">
										<span>{{sanji.aid}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="header_two_list_two" :class="{'header_two_list_two_now':headerindex==1}"
					@click="headerclick(1)">
					<span>首页</span>
				</div>
				<div class="header_two_list_two" :class="{'header_two_list_two_now':headerindex==2}"
					@click="headerclick(2)">
					<span>新品中心</span>
				</div>
				<div class="header_two_list_two" :class="{'header_two_list_two_now':headerindex==3}"
					@click="headerclick(3)">
					<span>领券中心</span>
				</div>
			</div>
		</div>
		<!-- 退出登录 -->
		<div class="login_out" v-if="login_outshow">
			<div class="login_out_content">
				<div class="login_out_header">
					<div class="login_out_header_border"></div>
					<span>提示</span>
				</div>
				<img src="../assets/34.png" alt="">
				<p>确认退出登录吗？</p>
				<div class="login_out_cance" @click="login_outshow = false">
					<span>取消</span>
				</div>
				<div class="login_out_cfirem" @click="logincanfirem">
					<span>确认</span>
				</div>
			</div>
		</div>
		<div id="container">

		</div>
		<div class="map_show" v-if="mapshow">
			<div class="map_show_content">
				<div class="mapSearch">
					<input placeholder="请输入你要查找的关键词" v-model="userInput" id="tishikuang"></input>
					<button class="map_btn" type="primary" @click.stop="biu">确定选址</button>
					<div class="cance_btn" @click.stop="mapshow=false">
						<span>取消</span>
					</div>
				</div>
				<div id="container2">

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AMapLoader from '@amap/amap-jsapi-loader';
	window._AMapSecurityConfig = {
		securityJsCode: '308e3ca18a744df48fe6640a177cc51b' //在这里填写你的安全密钥
	}

	import axios from "axios";
	export default {
		name: 'HelloWorld',
		data() {
			return {
        storestatus:"",//商家入驻状态信息
				userInfo: "", //个人中心信息
				useridents: "", //企业认证信息
				countryshow: false, //选择城市门板
				fenleishow: false, //分类选择门板状态
				headerindex: 1, //头部一级状态
				keywords: "", //搜索关键车
				searchshow: false, //选择搜索的弹窗
				login_outshow: false, //退出登录弹窗
				categorylist: [], //分类总数据
				yijiaid: "", //一级分类id
				erjifenlei: [], //二级分类数据
				erjiaid: "", //二级分类id
				cartListArr: [], //购物车数据
				basedetail: "", //基本信息
				povice: [], //省
				povicetext: "请选择省", //选择的省
				poviceshow: false, //省的选择展示状态
				citylist: [], //市
				citytext: "请选择市", //选择的市
				cityshow: false, //市的选择展示状态
				searchhistory: [], //历史搜索
				carttotal: 0, //购物车数量
				latitude: "",
				longitude: "",


				// 地图选点的参数
				map: null,
				autoOptions: {
					input: 'tishikuang' //绑定的搜索关键字的input标签ID，用这个注册
				},
				auto: null,
				userInput: '', //绑定的搜索关键字的的内容
				placeSearch: null,
				searchHere: null, //根据搜索组件搜索到以后的地点信息
				lat: "",
				lng: "",
				mapshow: false, //地图弹窗
			}
		},
		mounted() {
			let _this = this;
			// this.$http({
			// 	url: '649e954010762',
			// 	method: 'post',
			// 	data: JSON.stringify({
			// 		lng: localStorage.getItem("longitude"),
			// 		lat: localStorage.getItem("latitude"),
			// 	})
			// }).then((res) => {
			// 	if (res.data.code == 1) {
			// 		this.povicetext = res.data.data.province;
			// 		this.citytext = res.data.data.city;
			// 	}
			// });

			if (localStorage.getItem("headerindex")) {
				this.headerindex = localStorage.getItem("headerindex");
			} else {
				this.headerindex = 1;
			}
			if (localStorage.getItem("userInfo")) {
				// this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
				this.get_user_info(); //获取个人中心信息
				this.get_useridents(); //获取企业认证状态
				this.get_list(); //获取购物车
			} else {
				this.userInfo = "";
			}
			if (localStorage.getItem("povicetext")) {
				this.povicetext = localStorage.getItem("povicetext");
			} else {
				this.povicetext = "请选择省";
				this.getLocation(); //获取定位
			}
			if (localStorage.getItem("citytext")) {
				this.citytext = localStorage.getItem("citytext");
			} else {
				this.citytext = "请选择市";
			}
			if (localStorage.getItem("searchhistory")) {
				let historyarry = [];
				historyarry = JSON.parse(localStorage.getItem("searchhistory"));
				for (var i = 0; i < historyarry.length; i++) {
					if (_this.searchhistory.indexOf(historyarry[i]) == -1) {
						_this.searchhistory.push(historyarry[i]);
					}
				}
				//this.searchhistory = JSON.parse(localStorage.getItem("searchhistory"));
			} else {
				this.searchhistory = [];
			}
      this.get_storejoinin_status();//获取商家入驻状态
		},
		created() {
			this.linktab(); //判断是移动端还是pc端
			this.get_goodscategory(); //获取分类
			this.get_base(); //获取网站基本信息
			this.get_povice(); //获取选择省
			this.get_sender(); //获取仓库ID
		},
		methods: {
      //获取商家入驻状态
      get_storejoinin_status(){
        this.$http({
          url: '63732dbecbc14',
          method: 'get',
        }).then((res) => {
          if (res.data.code == 1) {
            this.storestatus = res.data.data;
          }
        });
      },
			//获取定位
			getLocation() {
				let that = this;
				AMapLoader.load({
						key: "4a6d12187e22eb1a3dab5ea5f714d64d", // 申请好的Web端开发者Key，首次调用 load 时必填
						version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
						plugins: [
							"AMap.ToolBar",
							"AMap.Scale",
							"AMap.HawkEye",
							"AMap.MapType",
							"AMap.Geolocation",
							"AMap.AutoComplete",
							"AMap.PlaceSearch",
							"AMap.Geocoder",
						], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
					}).then((AMap) => {
						that.map = new AMap.Map("container", {
							//设置地图容器id
							// viewMode: "3D", //是否为3D地图模式
							// zoom: 10, //初始化地图级别
							// position: [116.39, 39.9], //位置
							enableHighAccuracy: true,
							resizeEnable: true,
							// center: [105.602725, 37.076636], //初始化地图中心点位置
						});
						localStorage.setItem("latitude", that.map.getCenter().lat);
						localStorage.setItem("longitude", that.map.getCenter().lng);
						that.$http({
							url: '649e954010762',
							method: 'post',
							data: JSON.stringify({
								lng: that.map.getCenter().lng,
								lat: that.map.getCenter().lat,
							})
						}).then((res) => {
							if (res.data.code == 1) {
								that.povicetext = res.data.data.province;
								that.citytext = res.data.data.city;
							}
						});
					})
					.catch((e) => {
						console.log(e);
					});
				return;
			},

			//获取省
			get_povice() {
				this.$http({
					url: '652e4dbd321fc',
					method: 'post',
					data: JSON.stringify({
						level: "1",
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.povice = res.data.data;
					}
				});
			},

			//选择省点击事件
			poviceclick(item) {
				this.povicetext = item.name;
				this.citytext = "";
				localStorage.setItem("povicetext", item.name);
				localStorage.setItem("poviceid", item.id);
				this.get_city(item.id);
				this.poviceshow = false;
				this.cityshow = true;
			},

			//打开省选择框
			openpovice() {
				this.poviceshow = true;
				this.cityshow = false;
			},

			opencity() {
				this.poviceshow = false;
				this.cityshow = true;
				let poviceid = localStorage.getItem("poviceid");
				this.get_city(poviceid);
			},

			//获取市
			get_city(pid) {
				this.$http({
					url: '652e4dbd321fc',
					method: 'post',
					data: JSON.stringify({
						pid: pid,
						level: "2",
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.citylist = res.data.data;
					}
				});
			},

			//市选择事件
			cityclick(item) {
				this.citytext = item.name;
				localStorage.setItem("citytext", item.name);
				this.cityshow = false;
				this.poviceshow = false;
				this.countryshow = false;
			},

			//获取仓库id
			get_sender() {
				this.$http({
					url: '659515265f02f',
					method: 'post',
					data: JSON.stringify({
						lat: localStorage.getItem("latitude"),
						lng: localStorage.getItem("longitude"),
					})
				}).then((res) => {
					if (res.data.code == 1) {

					} else if (res.data.code == 0) {

					}
				});
			},

			//获取个人中心
			get_user_info() {
				this.$http({
					url: '5c78c4772da97',
					method: 'get',
				}).then((res) => {
					if (res.data.code == 1) {
						this.userInfo = res.data.data;
					}
				});
			},

			//获取网站基本信息
			get_base() {
				this.$http({
					url: '64cdb895b79ba',
					method: 'post',
					data: JSON.stringify({})
				}).then((res) => {
					if (res.data.code == 1) {
						this.basedetail = res.data.data;
					}
				});
			},

			//获取企业认证状态
			get_useridents() {
				this.$http({
					url: '65289e242332a',
					method: 'post',
					data: JSON.stringify({
						user_id: JSON.parse(localStorage.getItem("userInfo")).id,
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.useridents = res.data.data;
					}
				});
			},

			//获取分类
			get_goodscategory() {
				this.$http({
					url: '63e369d925758',
					method: 'post',
				}).then((res) => {
					if (res.data.code == 1) {
						this.categorylist = res.data.data;
						this.erjifenlei = res.data.data[0].children;
						this.yijiaid = res.data.data[0].aid;
					}
				});
			},

			//获取父组件传过来的商品数量
			getcartnumber(num) {
				this.carttotal = num;
			},

			//获取购物车
			get_list() {
				this.$http({
					url: '62bebd70c4bad',
					method: 'post',
				}).then((res) => {
					if (res.data.code == 1) {
						this.carttotal = res.data.data.length;
					}
				});
			},

			linktab() {
				let goUrl = this.isMobile();
				if (goUrl === 1) {
					//移动端地址

				} else {
					//PC地址
					// location = "https://www.denlike.com/";
				}
			},

			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				let goUrl = flag ? 1 : 0;
				return goUrl;
			},

			//跳转登录注册的事件
			loginregir(type) {
				this.$router.push({
					path: "login",
					query: {
						type: type
					}
				})
			},

			//省市选择切换事件
			countryclick() {
				this.countryshow = !this.countryshow;
				this.poviceshow = true;
				this.cityshow = false;
			},

			//头部选择事件
			headerclick(index) {
				this.headerindex = index;
				localStorage.setItem("headerindex", index);
				if (index == 1) {
					this.$router.push({
						path: "/"
					});
				} else if (index == 2) {
					this.$router.push({
						path: "newgoods"
					})
				} else {
					this.$router.push({
						path: "coupons"
					})
				}
			},

			//打开分类弹窗点击事件
			fenleiclick() {
				this.fenleishow = !this.fenleishow;
			},

			//一级点击事件
			yijiclick(children, aid) {
				this.erjifenlei = children;
				this.yijiaid = aid;
			},

			//二级点击事件
			erjiclick(list) {
        // localStorage.setItem("keywords",this.keywords)
        this.$emit('change', list.aid);
        // this.$router.push({
        //   path: "search"
        // })
        // this.searchshow = false;
				// console.log(list)
				if (list.ad_list.length == 0) {
					this.$router.push({
						path: "search",
						query: {
							fenleiid: list.aid
						}
					});
				}
			},

			//三级点击事件
			snajiclick(aid) {
				this.$router.push({
					path: "search",
					query: {
						fenleiid: aid
					}
				});
			},

			//退出登录确定事件
			logincanfirem() {
				let url = window.location.href;
				this.$message({
					message: "退出成功",
					type: 'success'
				});
				localStorage.clear();
				this.login_outshow = false;
				// if(url=="https://349730k70o.oicp.vip/#/"){
				if(url=="https://web.maijieduo.com/#/"){
					window.location.reload();
				}else{
					this.$router.push({
						path: "/"
					});
				}
			},

			//跳转搜索页面
			gosearch() {
				let _this = this;
				if (this.keywords != "") {
					// this.$message({
					// 	message: "搜索关键字不能为空",
					// 	type: "error"
					// })
					// return;
					_this.searchhistory.push(_this.keywords);
				}
				localStorage.setItem("searchhistory", JSON.stringify(_this.searchhistory));
				let searchlist = [];
				_this.searchhistory = [];
				searchlist = JSON.parse(localStorage.getItem("searchhistory"));
				console.log(searchlist)
				for (var i = 0; i < searchlist.length; i++) {
					if (_this.searchhistory.indexOf(searchlist[i]) == -1) {
						_this.searchhistory.push(searchlist[i]);
					}
				}
				localStorage.setItem("keywords",this.keywords)
				this.$emit('change', this.keywords);
				this.$router.push({
					path: "search"
				})
				this.searchshow = false;
			},

			//本页面列表搜索
			searcgclick(item) {
				localStorage.setItem("keywords",item);
				this.keywords = item;
				this.$emit('change', item);
				this.searchshow = false;
			},

			//删除历史搜索
			delateclick(index) {
				this.searchhistory.splice(index, 1);
				localStorage.setItem("searchhistory", JSON.stringify(this.searchhistory));
				this.searchhistory = JSON.parse(localStorage.getItem("searchhistory"));
				this.searchshow = false;
			},

			clearall() {
				localStorage.removeItem("searchhistory");
				this.searchhistory = [];
				this.searchshow = false;
			},

			//分类点击跳转商品列表
			goshoplist() {
				this.$router.push({
					path: "shoplist"
				})
			},

			//跳转购物车
			gocart() {
				if (this.userInfo) {
					this.$router.push({
						path: "goodscart"
					})
				} else {
					this.$message({
						message: "请先登录",
						type: 'error'
					});
				}
			},

			//跳转个人中心
			goselfcenter(type) {
				if (this.userInfo) {
					if (type == 1) {
						localStorage.setItem("leftindex", 1);
						this.$router.push({
							path: "orderlist"
						})
					} else if (type == 2) {
						this.$router.push({
							path: "selfcenter"
						})
					} else if (type == 3) {

            if(this.storestatus==0 ||this.storestatus==-1){
              //商家入驻 判断是否已经入驻
              localStorage.setItem("leftindex", 3);
              this.$router.push({
                path: "shangjiajointext"
              })
            }else{
              localStorage.setItem("leftindex", 3);
              this.$router.push({path:"shangjiajoinshenhe"})
            }
						//商家入驻
						// localStorage.setItem("leftindex", 3);
						// this.$router.push({
						// 	path: "shangjiajointext"
						// })
					} else if (type == 4) {
						// 帮助中心
						localStorage.setItem("leftindex", 16);
						this.$router.push({
							path: "helpfaceback"
						})
					}
				} else {
					this.$message({
						message: "请先登录",
						type: 'error'
					});
				}
			},

			//跳转首页
			goindex() {
				this.$router.push({
					path: "/"
				})
			},

			//打开地图
			openmap() {
				this.mapshow = true;
				this.initMapindex();
			},

			//地图选点
			// poi搜索以后 点击确认选址以后的操作
			biu() {
				let _this = this;
				if (this.searchHere) {
					localStorage.setItem("latitude", _this.searchHere.location.lat);
					localStorage.setItem("longitude", _this.searchHere.location.lng);
					_this.mapshow = false;
					_this.$http({
						url: '649e954010762',
						method: 'post',
						data: JSON.stringify({
							lng: _this.searchHere.location.lng,
							lat: _this.searchHere.location.lat,
						})
					}).then((res) => {
						if (res.data.code == 1) {
							_this.povicetext = res.data.data.province;
							_this.citytext = res.data.data.city;
							localStorage.setItem("povicetext", _this.povicetext);
							localStorage.setItem("citytext", _this.citytext);
						}
					});
					return
					this.lat = this.searchHere.location.lat;
					this.lng = this.searchHere.location.lng;
					this.detail = this.searchHere.district + this.searchHere.address + this.searchHere.name;
					axios.request({
						method: "GET",
						header: {
							"Content-Type": "application/text",
						},
						url: "https://restapi.amap.com/v3/geocode/regeo?output=JSON&location=" +
							this.searchHere.location.lng.toString() +
							"," +
							this.searchHere.location.lat.toString() +
							"&key=73d80c60a8a72d4fccb5beafaed20013&radius=1000&extensions=all",
					}).then((response) => {
						if (response.status === 200) {
							this.oncepovicecode = response.data.regeocode.addressComponent.province;
							this.oncecitycode = response.data.regeocode.addressComponent.city;
							this.oncequcode = response.data.regeocode.addressComponent.district;
						} else {
							console.log("获取信息失败，请重试！");
						}
					});
				} else {
					this.$message({
						message: "定位失败",
						type: 'error'
					});
				}
			},

			//初始化地图
			initMapindex() {
				AMapLoader.reset()
				AMapLoader.load({
					key: "4a6d12187e22eb1a3dab5ea5f714d64d", // 申请好的Web端开发者Key，首次调用 load 时必填
					version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
					plugins: ['AMap.ToolBar', 'AMap.Scale', 'AMap.HawkEye', 'AMap.MapType',
						'AMap.Geolocation', 'AMap.AutoComplete', 'AMap.PlaceSearch', 'AMap.Geocoder'
					], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
				}).then((AMap) => {
					this.map = new AMap.Map("container2", { //设置地图容器id
						viewMode: "3D", //是否为3D地图模式
						zoom: 17, //初始化地图级别
						resizeEnable: true
						// center: [105.602725, 37.076636], //初始化地图中心点位置
					});
					const marker = new AMap.Marker({
						position: ['', ''], //位置
					});
					this.map.add(marker); //添加到地图
					//引入高德地图的空间绑定使用
					this.map.addControl(new AMap.Scale())
					this.map.addControl(new AMap.ToolBar())
					this.map.addControl(new AMap.HawkEye())
					this.map.addControl(new AMap.MapType())
					this.map.addControl(new AMap.Geolocation())
					this.auto = new AMap.AutoComplete(this.autoOptions)
					//
					this.placeSearch = new AMap.PlaceSearch({
						map: this.map
					}) //构造地点查询类
					this.auto.on('select', this.select) //绑定查询事件

				}).catch(e => {
					console.log(e, 11111);
				})
			},

			//poi搜索 这里面的这个参数 e 就是搜索以后的地址信息 你可以把它打印出来看一看经纬度什么的都有
			select(e) {
				console.log(e)
				this.lat = e.poi.location.lat;
				this.lng = e.poi.location.lng;
				this.placeSearch.setCity(e.poi.adcode) //依据城市ID搜索
				this.placeSearch.search(e.poi.name) //关键字查询查询
				this.map.setZoom(10, true)
				this.searchHere = e.poi;
				axios.request({
					method: "GET",
					header: {
						"Content-Type": "application/text",
					},
					url: "https://restapi.amap.com/v3/geocode/regeo?output=JSON&location=" +
						this.searchHere.location.lng.toString() +
						"," +
						this.searchHere.location.lat.toString() +
						"&key=73d80c60a8a72d4fccb5beafaed20013&radius=1000&extensions=all",
				}).then((response) => {
					if (response.status === 200) {
						this.oncepovicecode = response.data.regeocode.addressComponent.province;
						this.oncecitycode = response.data.regeocode.addressComponent.city;
						this.oncequcode = response.data.regeocode.addressComponent.district;
					} else {
						console.log("获取信息失败，请重试！");
					}
				});
				this.$message({
					message: '选择地点:' + this.searchHere.name,
					type: 'success'
				});
			},


		},
	}
</script>
<style scoped>
	.login_header {
		width: 100%;
		height: 176px;
		position: relative;
		cursor: pointer;
	}

	.login_header_bg {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.login_header_content {
		width: 1200px;
		height: 100%;
		margin: 0px auto;
		position: relative;
	}

	.login_header_one {
		width: auto;
		height: 20px;
		color: #333333;
		font-size: 14px;
		font-weight: 400;
		color: #333333;
		line-height: 20px;
		float: left;
		margin-top: 10px;
	}

	.login_header_one img {
		width: 20px;
		height: 20px;
		position: relative;
		top: 6px;
		margin-right: 8px;
	}

	.login_header_two {
		width: auto;
		height: 20px;
		float: right;
		margin-top: 10px;
		line-height: 20px;
	}

	.login_header_two_list {
		width: auto;
		height: 100%;
		border-left: 1px solid #333333;
		font-size: 14px;
		font-weight: 400;
		color: #666666;
		padding-left: 24px;
		float: right;
		padding-right: 24px;
	}

	.login_header_two_list img {
		width: 10px;
		height: 6px;
		position: relative;
		left: 4px;
		top: -2px;
	}

	.user_img {
		width: 24px !important;
		height: 24px !important;
		margin-right: 10px;
		position: relative;
		top: 2px;
		border-radius: 50%;
	}

	.login_header_two_list_loginout {
		position: relative;
		top: -8px;
	}

	.header_three {
		width: 1200px;
		height: 74px;
		float: left;
		margin-top: 42px;
	}

	.header_three_logo {
		width: 244px;
		height: 100%;
		float: left;
	}

	.header_three_logo img {
		width: 100%;
		height: 100%;
	}

	.header_three_search {
		width: auto;
		float: left;
		margin-left: 16px;
		position: relative;
	}

	.search_tanchuang {
		width: 564px;
		height: 280px;
		background: #FFFFFF;
		box-shadow: 0px 3px 16px 1px rgba(0, 0, 0, 0.06);
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #EEEEEE;
		z-index: 9;
		position: absolute;
		top: 50px;
		left: 0;
		overflow-y: scroll;
	}

	.search_tanchuang::-webkit-scrollbar {
		display: none;
	}

	.search_tanchuang_list {
		width: 516px;
		float: left;
		height: 50px;
		line-height: 50px;
		margin-left: 24px;
		font-size: 16px;
		font-weight: 400;
		color: #666666;
		position: relative;
		cursor: pointer;
	}

	.search_tanchuang_list:last-child {
		border-bottom: 1px solid #EEEEEE;
	}

	.search_tanchuang_list img {
		width: 16px;
		height: 16px;
		position: absolute;
		right: 0;
		top: 17px;
	}

	.search_tanchuang_bottom {
		width: 516px;
		float: left;
		line-height: 50px;
		margin-left: 24px;
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		text-align: right;
		cursor: pointer;
	}

	.search_tanchuang_bottom img {
		width: 16px;
		height: 16px;
		position: relative;
		top: 2px;
		margin-right: 4px;
	}

	.header_three_search_one {
		width: auto;
		height: 46px;
	}

	.header_three_search_one_l {
		width: 680px;
		height: 44px;
		background: #FFFFFF;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #10C380;
		float: left;
	}

	.header_three_search_one_l input {
		width: 560px;
		height: 100%;
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		outline: none;
		border: none;
		text-indent: 20px;
	}

	.header_three_search_one_l_btn {
		width: 116px;
		height: 44px;
		background: #10C380;
		border-radius: 0px 4px 4px 0px;
		float: right;
		text-align: center;
		line-height: 44px;
		font-size: 18px;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
	}

	.header_three_search_one_l_btn img {
		width: 24px;
		height: 24px;
		position: relative;
		top: 6px;
		margin-right: 6px;
	}

	.header_three_search_one_r {
		float: left;
		width: 182px;
		height: 44px;
		background: #FFFFFF;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #10C380;
		margin-left: 20px;
		font-size: 16px;
		font-weight: 400;
		color: #10C380;
		line-height: 44px;
	}

	.header_three_search_one_r_cart {
		width: 30px;
		height: 30px;
		position: relative;
		float: left;
		margin-top: 7px;
		margin-left: 42px;
		margin-right: 22px;
	}

	.header_three_search_one_r_cart img {
		width: 28px;
		height: 28px;
	}

	.header_three_search_one_r_cart span {
		width: 14px;
		height: 14px;
		line-height: 14px;
		display: block;
		background: #FF5050;
		border-radius: 50%;
		text-align: center;
		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
		position: absolute;
		right: 0;
		top: 0;
	}

	.header_three_search_bottom {
		width: auto;
		height: 20px;
		line-height: 20px;
		margin-top: 8px;
	}

	.header_three_search_bottom_list {
		font-size: 14px;
		font-weight: 400;
		color: #666666;
		float: left;
		border-right: 1px solid #999999;
		padding-left: 12px;
		padding-right: 12px;
	}

	.header_three_search_bottom_list:last-child {
		border: none;
	}

	/* 城市选择弹窗 */
	.check_city {
		width: 544px;
		height: 248px;
		background: #FFFFFF;
		box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.16);
		border-radius: 0px 0px 0px 0px;
		position: absolute;
		left: 0;
		top: 40px;
		z-index: 9;
	}

	.check_city_header {
		width: 100%;
		height: 26px;
		margin-top: 10px;
		border-bottom: 1px solid #e6e6e6;
		line-height: 26px;
	}

	.check_city_header_list {
		width: auto;
		height: 100%;
		border: 1px solid #e6e6e6;
		font-size: 12px;
		font-weight: 400;
		color: #333333;
		padding-left: 24px;
		padding-right: 24px;
		float: left;
		margin-left: 10px;
	}

	.check_city_header_list_now {
		border-bottom: none;
		height: 27px;
		background-color: #FFFFFF;
	}

	.check_city_content {
		width: 100%;
		overflow: hidden;
	}

	.check_city_content_list {
		width: auto;
		font-size: 12px;
		font-weight: 400;
		color: #333333;
		float: left;
		padding: 4px 8px;
		margin-left: 10px;
		margin-top: 10px;
	}

	.check_city_content_list_now {
		background: #10C380;
		color: #FFFFFF;
	}

	.header_two {
		width: 100%;
		height: 56px;
		line-height: 56px;
		background: linear-gradient(270deg, #FFFFFF 0%, #F7F7F7 100%);
	}

	.header_two_content {
		width: 1200px;
		height: 100%;
		margin: 0 auto;
		text-align: center;
	}

	.header_two_list_one {
		width: 244px;
		height: 56px;
		background: #10C380;
		font-size: 18px;
		font-weight: bold;
		color: #FFFFFF;
		float: left;
		cursor: pointer;
		position: relative;
	}

	.header_two_list_one img {
		width: 18px;
		height: 18px;
		position: relative;
		top: 3px;
		margin-right: 10px;
	}

	.header_two_list_one_fenlei {
		width: 750px;
		height: 422px;
		background: #FFFFFF;
		border-radius: 0px 0px 0px 0px;
		position: absolute;
		left: 0;
		top: 56px;
		z-index: 9;
	}

	.header_two_list_one_fenlei_l {
		width: 200px;
		height: 100%;
		float: left;
		overflow-y: scroll;
	}

	.header_two_list_one_fenlei_l::-webkit-scrollbar {
		display: none;
	}

	.header_two_list_one_fenlei_l_yijilist {
		width: 100%;
		height: 45px;
		font-size: 16px;
		font-weight: 400;
		color: #333333;
		line-height: 45px;
		text-align: left;
		text-indent: 20px;
		position: relative;
	}

	.header_two_list_one_fenlei_l_yijilist img {
		width: 6px;
		height: 10px;
		position: absolute;
		right: 20px;
		top: 17px;
	}

	.header_two_list_one_fenlei_l_yijilist_now {
		background: #EEEEEE;
		font-size: 16px;
		font-weight: bold;
		color: #333333;
	}

	.header_two_list_one_fenlei_l_erji {
		width: 520px;
		height: 100%;
		float: left;
		overflow-y: scroll;
	}

	.header_two_list_one_fenlei_l_erji::-webkit-scrollbar {
		display: none;
	}

	.header_two_list_one_fenlei_l_erji_list {
		width: 100%;
		overflow: hidden;
	}

	.header_two_list_one_fenlei_l_erji_list_l {
		width: 120px;
		height: 45px;
		float: left;
		font-size: 16px;
		font-weight: bold;
		color: #10C380;
	}

	.header_two_list_one_fenlei_l_erji_list_r {
		width: 400px;
		overflow: hidden;
		float: left;
	}

	.header_two_list_one_fenlei_sanjilist {
		width: auto;
		height: auto;
		float: left;
		font-size: 16px;
		font-weight: 400;
		color: #666666;
		margin-right: 24px;
		line-height: 56px;
	}



	.header_two_list_two {
		width: auto;
		font-size: 16px;
		font-weight: 400;
		color: #333333;
		height: 100%;
		float: left;
		padding-left: 53px;
		padding-right: 53px;
		cursor: pointer;
	}

	.header_two_list_two_now {
		font-size: 16px;
		font-weight: bold;
		color: #10C380;
	}

	/* 退出登录弹窗 */
	.login_out {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 9;
	}

	.login_out_content {
		width: 460px;
		height: 320px;
		background: #FFFFFF;
		border-radius: 0px 0px 0px 0px;
		border: 1px solid #707070;
		margin: 200px auto;
	}

	.login_out_header {
		width: 100%;
		/* width: 560px; */
		height: 46px;
		background: #F9F9F9;
		border_bottom: 1px solid #E2E2E2;
		font-size: 16px;
		font-weight: 500;
		color: #333333;
		line-height: 46px;
	}

	.login_out_header_border {
		width: 4px;
		height: 14px;
		background: #10C380;
		border-radius: 2px 2px 2px 2px;
		float: left;
		margin-left: 24px;
		margin-top: 16px;
		margin-right: 16px;
	}

	.login_out_content img {
		width: 54px;
		height: 54px;
		display: block;
		margin: 40px auto;
	}

	.login_out_content p {
		font-size: 14px;
		font-weight: 400;
		color: #333333;
		text-align: center;
	}

	.login_out_cance {
		width: 140px;
		height: 42px;
		background: #EEEEEE;
		border-radius: 4px 4px 4px 4px;
		text-align: center;
		line-height: 42px;
		float: left;
		margin-left: 78px;
		margin-top: 41px;
		cursor: pointer;
	}

	.login_out_cfirem {
		width: 140px;
		height: 42px;
		background: #10C380;
		border-radius: 4px 4px 4px 4px;
		text-align: center;
		line-height: 42px;
		float: left;
		margin-left: 24px;
		color: #FFFFFF;
		margin-top: 41px;
		cursor: pointer;
	}

	.map_show {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 9;
	}

	.map_show_content {
		width: 70%;
		height: 70%;
		background-color: #FFFFFF;
		margin: 5% auto;
	}

	.mapSearch {
		display: flex;
		margin-bottom: 8px;
		margin: 0px auto 50px auto;
		text-align: center;
		width: 520px;
	}

	.map_btn {
		width: 140px;
		font-size: 18px;
		margin-left: 20px;
		background-color: #10C380;
		color: #fff;
		border: none;
		cursor: pointer;
		height: 50px;
		margin-top: 50px;
	}

	#tishikuang {
		display: block;
		width: 300px;
		height: 50px;
		font-size: 16px;
		text-indent: 10px;
		outline: none;
		margin-top: 50px;
	}

	#container2 {
		width: 600px;
		height: 400px;
		margin: 0 auto;
	}

	.cance_btn {
		width: 120px;
		height: 50px;
		background-color: #eee;
		float: right;
		margin-left: 24px;
		margin-top: 50px;
		font-size: 16px;
		line-height: 50px;
		border-radius: 16rpx;
		cursor: pointer;
	}
</style>